import { Component } from '@angular/core';
import { BasicDetailsComponent } from '../basic-details/basic-details.component';
import { ResultComponent } from '../result/result.component';
import { Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { MealDetailsComponent } from '../meal-details/meal-details.component';
import { NgIf } from '@angular/common';
import { DatePipe } from '@angular/common';
import { MealPlanService } from '../meal-plan.service';
import { FormsModule } from '@angular/forms';
import { FastingComponent } from '../fasting/fasting.component';
import { Output, EventEmitter } from '@angular/core';
import { SingleMealComponent } from '../single-meal/single-meal.component';
//import { ToastrService } from 'ngx-toastr';
import { ToastService } from '../../services/toast.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { Router } from "@angular/router";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';

@Component({
  selector: 'app-result-meal-plan',
  standalone: true,
  imports: [NgxMaterialTimepickerModule,MatProgressSpinnerModule,BasicDetailsComponent,ResultComponent,NgFor,MealDetailsComponent,NgIf,FormsModule,FastingComponent,SingleMealComponent],
  templateUrl: './result-meal-plan.component.html',
  styleUrl: './result-meal-plan.component.css'
})
export class ResultMealPlanComponent {
  @Input() result:any;
  @Input() assignMeal:any;
  @Input() allPrefer:any;
  @Output() backEvent = new EventEmitter<string>();
  isResult:boolean = true;
  isSingle:boolean=false;
  viewDetials:any=[];
  mealDetails:any=[];
  fastingIndex:any=[];
  fastingDate:any=[];
  fastingStartTime:any=[];
  fastingEndTime:any=[];
  fastingDay:any=[];
  backType:any;
  isSingleMeal:boolean=false;
  singleMealIndex:any;
  singleMealDate:any;
  timeExit:boolean=false;
  isTimeDuplicate:any=1;
  isLoading:any=2;
  isBackButton:boolean =false;
  isAnyTimeBlank:any=[];
  constructor(private datePipe: DatePipe,private mealService:MealPlanService,private toast:ToastService,private route:Router) {
      
  }

  saveMealPlan(){
    //console.log(this.isAnyTimeBlank);
    if(this.isTimeDuplicate == 1){
    this.mealDetails = {mealName:this.assignMeal.name,meals:this.result,memberId:this.assignMeal.memberId,fromDate:this.assignMeal.fromDate,toDate:this.assignMeal.toDate};
    
    console.log(this.isAnyTimeBlank);
      if(this.isAnyTimeBlank.length > 0){
        this.toast.errorMessage('Please add all times.');
      }else{
        this.isLoading = 1;
   
    this.mealService.saveMealPlan(this.mealDetails).subscribe(response => { 
//      this.ToastrService.success('Meal plan created and assigned successfully.');
      this.isLoading = 2;
      if(response == 1){
        this.toast.errorMessage('Meal plan already assigned to selected date range.');
      }else if(response == 2){
         this.toast.errorMessage('Internal server error! Please try again.');
      }
      else if(response == 3){
         this.toast.errorMessage('Bad gateway/gateway timeout! Please try again.');
      }
      else if(response == 4){
         this.toast.errorMessage('Some exception occurred! Please try again.');
      }
      else{
        this.toast.successMessage('Meal plan created and assigned successfully.');
      setTimeout(()=>{                           // <<<---using ()=> syntax
        this.route.navigate(['/all-meal-plans'],{queryParams: {memberId:this.assignMeal.memberId}});
    }, 5000);
      
      }
      

  });

}
}else{
    this.toast.errorMessage('There some duplicate time exist.');
}
  }

  viewDetails(data:any){
      this.viewDetials = data;
      //console.log( this.viewDetials );
      this.isResult = false;
      this.isSingle = true;
      this.isBackButton =true;
      this.backEvent.emit('3');
  }

  viewItem1(data:any){
    console.log('here',data);
    this.isResult = true;
    this.isSingleMeal = false;
  }

  
  getDate(selectedTime:any){
    // const timeParts = selectedTime.split(':');
    const selectedDate = this.convertDateToUTC(new Date(selectedTime));
   
   return this.datePipe.transform(selectedDate, 'd MMM Y');
  }
  convertDateToUTC(date:any) { 
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
   }

  removeRow(index:any,mainIndex:any){
    this.result[mainIndex].recipes.splice(index,1);
    if(this.isAnyTimeBlank.length > 0){
      console.log(this.isAnyTimeBlank.length);
      this.isAnyTimeBlank.splice(0,1);
    }
    //this.ToastrService.success('Row removed successfully.');
  }

  addFasting(index:any,date:any,day:any){
    this.fastingIndex = index;
    this.fastingDate = date;
    this.fastingDay = day;
  }

  saveFasting(){
    var timeStart = new Date("01/01/2007 " + this.fastingStartTime).getHours();
var timeEnd = new Date("01/01/2007 " + this.fastingEndTime).getHours();

var hourDiff = timeEnd - timeStart;   
console.log(this.result[this.fastingIndex]); 
if(hourDiff <  0){
  this.toast.errorMessage('End time should be greater than start time.');
}else{
let returnVal = this.checkTime(this.fastingIndex,this.getTime(this.fastingStartTime),this.getTime(this.fastingEndTime));
console.log(returnVal);



if(returnVal < 2){
if(this.result[this.fastingIndex].fasting === undefined){
  this.result[this.fastingIndex].fasting=[{fromTime:this.getTime(this.fastingStartTime),toTime:this.getTime(this.fastingEndTime),duration:hourDiff.toString()}];
}  
else{
  this.result[this.fastingIndex].fasting.push({fromTime:this.getTime(this.fastingStartTime),toTime:this.getTime(this.fastingEndTime),duration:hourDiff.toString()});
}    
    this.fastingStartTime = '';
    this.fastingEndTime='';
    console.log(this.result[this.fastingIndex]);
    //this.ToastrService.success('Fasting added successfully.');
    this.toast.successMessage('Fasting added successfully.');
}else{
  this.timeExit = true;
  //console.log('Already have time');;
  this.toast.errorMessage('Already have time.');
}
  }
  }

checkTime(index:any,startTime:any,endTime:any){
  let j=1;
  startTime = startTime.split(':');
  console.log(startTime);
  //let endTime:any = '19:00'; 
  endTime = endTime.split(':');
 
  for(let i=0;i<this.result[index].recipes.length;i++){
    //let startTime:any = '12:00'; 
    //console.log('here',this.result[index].recipes[i].time);   
   

   if(this.result[index].recipes[i].time >= startTime[0] &&  this.result[index].recipes[i].time <= endTime[0]){
      j++;
    }
  }
  return j;
}
  
  removeFastingRow(index:any,mainIndex:any){
    this.result[mainIndex].fasting.splice(index,1);
   // this.ToastrService.success('Fasting removed successfully.');
   this.toast.successMessage('Fasting removed successfully.');
  }

  getTime(selectedTime:any){
    const timeParts = selectedTime.split(':');
    const selectedDate = new Date();
    selectedDate.setHours(parseInt(timeParts[0], 10));
    selectedDate.setMinutes(parseInt(timeParts[1], 10));
   return this.datePipe.transform(selectedDate, 'hh:mm a');
  }

  addMeal(index:any,mealDate:any){
      // console.log(index);
      // console.log(mealDate);
      this.singleMealIndex = index;
      this.singleMealDate = mealDate;
      this.isResult =false;
      this.isSingleMeal = true;
      this.isBackButton =true;
      this.backEvent.emit('3');

  }

  resultPage(data:any){
    this.isResult =true;
    this.isSingleMeal = false;
    this.isAnyTimeBlank.push(1);
    this.toast.errorMessage('Please add the time');
  }

  backButton(){
    //console.log('here now just now');
      this.isResult = true;
      this.isSingle = false;
      this.isSingleMeal=false;
     this.isBackButton=false;
    this.backEvent.emit('2');
  }

  checkTimeExist(data:any,index:any){
    console.log(data);
    if(data != ''){
      console.log(data);
     // console.log(this.result);
     // console.log(this.result[index]);
      const timeVal = this.result[index].recipes.filter((x:any) => x.time == data); 
     if(timeVal.length > 1){
      //console.log('Duplicate Time Exist');
      this.isTimeDuplicate = 2;
      this.result[index].time='';
     // this.isAnyTimeBlank.push(1);
      this.toast.errorMessage('Duplicate time exist.');
     
      //this.meal[index].time='';
     }else{
      this.isAnyTimeBlank.splice(0,1);
      this.result[index].time=data;
      this.isTimeDuplicate = 1;
     }
    }else{
      console.log(this.isAnyTimeBlank);
      //this.isAnyTimeBlank.push(1);
    }
    
  }
}
